.barren {
  background-color: gray;
}

.desert {
  background-color: gold;
}

.forest {
  background-color: forestgreen;
}

.mountains {
  background-color: red;
}

.ocean {
  background-color: blue;
}

.snow {
  background-color: snow;
}

.grassland {
  background-color: springgreen;
}

.space {
  background-color: black;
}